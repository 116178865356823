.home {
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-secondary);
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  padding: 2rem;
  border-radius: 8px;
}

h1 {
  font-size: 5rem;
  margin: 0;
}

p {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.ctaButton {
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1.25rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
}

.ctaButton:hover {
  background-color: var(--color-primary);
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.25rem;
  }
}
