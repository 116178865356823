.faq {
  padding: 2rem;
  text-align: center;
  background-color: var(--color-muted);
}

.faq h2 {
  margin-bottom: 2rem;
  color: var(--color-black);
}

.faq .tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.faq .tabs button {
  padding: 1em;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
  font-weight: bold;
}

.faq .tabs .active {
  background-color: var(--color-accent);
  box-shadow: 3px 3px 0px 0px var(--color-black);
}


@media (max-width: 768px) {
  .faq .tabs {
    flex-wrap: wrap;
  }
}