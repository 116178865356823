.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
}

.cookieSettings {
  position: fixed;
  bottom: 0;
  background-color: var(--color-white);
  color: var(--color-black);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 80%;
  max-width: 600px;
  margin: 1rem;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
}

.option {
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.buttonContainer button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: var(--color-white);
  background-color: var(--color-primary);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
}

.buttonContainer button:hover {
  background-color: var(--color-secondary);
}
