.gallery {
  padding: 2rem;
  background-color: var(--color-primary);
  text-align: center;
  color: var(--color-secondary);
}

.slider {
  width: 40%;
  margin: 0 auto;
}

.imageContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 500px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .gallery h2 {
    margin-bottom: 0;
  }
  .slider {
    width: 100%;
  }

  .imageContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 500px;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
