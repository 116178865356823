.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupContent {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  text-align: center;
  margin: 20px;
}

.popupContent button {
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--color-secondary);
  background-color: var(--color-primary);
  border: 1px solid var(--color-black);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
}

.popupContent button:hover {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}
