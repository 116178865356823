.footer {
  background-color: var(--color-dark-grey);
  color: var(--color-secondary);
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.logoSection,
.legalSection,
.socialSection {
  margin: 1rem;
}

.logo {
  height: 200px;
}

.legalSection {
  text-align: left;
  width: 100%;
}

.legalLink {
  display: block;
  text-decoration: none;
  color: var(--color-secondary);
  margin: 0.5rem 0;
  font-size: 1em;
}

.legalLinkButton {
  color: var(--color-secondary);
  background-color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1em;
  font-family: Fira Sans Extra Condensed;
  margin: 0.5rem 0;
}

.legalLink:hover {
  color: var(--color-accent);
}

.compLink {
  text-decoration: none;
  color: var(--color-white);
  font-family: "Source Code Pro", serif;
  font-optical-sizing: auto;
  font-weight: bold;
}

.socialSection {
  display: flex;
  gap: 1rem;
}

.socialLink {
  color: var(--color-secondary);
  font-size: 1.5rem;
  text-decoration: none;
}

.socialLink:hover {
  color: var(--color-accent);
}

.scrollTop {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: 2px solid var(--color-black);
  box-shadow: 3px 3px 0px -1px var(--color-black);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
  padding: 20px;
  margin: 1rem;
  z-index: 100;
}

.legalSectionInner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .legalSectionInner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
}
