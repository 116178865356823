.notFound {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.notFound h1 {
  font-size: 6rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.notFound p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.notFound button {
  padding: 1rem;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--color-secondary);
  background-color: var(--color-primary);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
}

.notFound button:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
}

@media screen and (max-width: 768px) {  .notFound h1 {
    font-size: 4rem;
  }

  .notFound p {
    font-size: 1.25rem;
  }
}
