/* Desktop Header styles (unchanged from original) */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 8px var(--color-black);
}

.header .title {
  font-size: 2rem;
  color: var(--color-secondary);
}

.separator {
  margin: 0 10px;
  color: var(--color-secondary); /* Adjust the color as needed */
  font-size: 18px; /* Adjust the size as needed */
}

.logo {
  height: 60px;
}

.nav {
  display: flex;
  gap: 1rem;
}

.navItem {
  text-decoration: none;
  color: var(--color-secondary);
  font-weight: bold;
  font-size: 1.25em;
  position: relative;
}

.navItem::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--color-accent);
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.navItem:hover::after {
  width: 100%;
}

.active::after {
  width: 100%;
}

.languageSelector {
  display: flex;
  gap: 0.5rem;
}

.flag {
  width: 24px;
  cursor: pointer;
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 0.3rem;
  cursor: pointer;
  z-index: 10;
}

.hamburgerLine {
  width: 25px;
  height: 3px;
  background-color: var(--color-secondary);
  transition: transform 0.3s, opacity 0.3s;
}

.hamburgerLine.open:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburgerLine.open:nth-child(2) {
  opacity: 0;
}

.hamburgerLine.open:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Subnav for Desktop (original setup for mobile menu) */
.subnav {
  display: none;
}

/* Mobile-Specific Styles */
@media (max-width: 1200px) {
  .header h1 {
    font-size: 1.75rem;
  }

  .nav {
    display: none;
  }

  .navItem {
    color: var(--color-primary);
  }

  .hamburger {
    display: flex;
  }

  .languageSelector {
    display: none;
  }

  .languageSelectorMobile {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  /* Subnav for Mobile Menu */
  .subnav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%; /* Hidden off-screen */
    width: 60%;
    background-color: var(--color-secondary);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 0 8px var(--color-black);
    z-index: 15; /* Above overlay */
    transition: left 0.3s ease-in-out; /* Slide-in transition */
  }

  .subnavInner {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .subnav.open {
    left: 0; /* Slide-in when open */
  }

  /* Links within the subnav */
  .subnav a {
    text-decoration: none;
    color: var(--color-primary);
    font-weight: bold;
    font-size: 1.25rem;
    padding: 1rem 0;
    width: 100%;
    text-align: center;
    transition: color 0.3s ease;
  }

  .subnav a:hover {
    color: var(--color-accent); /* Change color on hover */
  }

  /* Language Selector for Mobile */
  .languageSelectorMobile {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  .flag {
    width: 40px;
    height: auto;
    cursor: pointer;
  }
}

/* Overlay (for mobile only) */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 10; /* Above content */
  animation: fadeIn 0.3s ease; /* Smooth fade-in animation */
}

/* Fade-in animation for the overlay */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
