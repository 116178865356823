:root {
  --color-primary: #2a4130;
  --color-secondary: #eddca4;
  --color-accent: #71b381;
  --color-muted: #6f875f;
  --color-grey: #919c9c;
  --color-dark-grey: #333;
  --color-white: #fcfbf4;
  --color-black: #000;
}
