.about {
  padding: 2rem;
  text-align: center;
}

.aboutContent {
  width: 100%;
  display: flex;
}

.description {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.openingHours {
  width: 40%;
}

.openingHoursInner {
  margin: 10%;
  padding: 1rem;
  background-color: var(--color-muted); /* Sticky note yellow */
  color: var(--color-white);
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
  border: 1px solid #ccc;
  transform: rotate(-3deg);
  position: relative;
}

.openingHoursInner::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  width: 20px;
  height: 10px;
  background: #ccc;
  border-radius: 10px 10px 0 0;
  transform: translateX(-50%);
}

.openingHours h3 {
  margin-bottom: 1rem;
}

.openingHours ul {
  list-style-type: none;
  padding: 0;
}

.openingHours li {
  margin: 0.5rem 0;
}

@media screen and (max-width: 768px) {
  .aboutContent {
    flex-direction: column;
  }

  .description {
    width: 100%;
  }

  .openingHours {
    width: 100%;
  }
}
