.services {
  padding: 2rem;
  background-color: var(--color-muted);
  text-align: center;
}

.services h2 {
  margin-bottom: 2rem;
  color: var(--color-black);
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  padding: 0 1rem;
}

.card {
  background-color: var(--color-primary);
  color: var(--color-white);
  flex: 0 1 100%;
  max-width: 400px;
  padding: 1.5rem;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-2px) scale(1.05);
}

.cardTitle {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.cardDetail {
  margin-bottom: 0.5rem;
}

.cardDescription {
  overflow: hidden;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 2px solid var(--color-white);
}

.icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.expanded .icon {
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .services {
    padding: 3rem;
  }
}