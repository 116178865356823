.accordion {
  margin: 1rem 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
}

.question {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-black);
}

.question h3 {
  margin: 0;
  font-size: 1em;
  text-align: left;
}

.icon {
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

.answer {
  background-color: var(--color-secondary);
  color: var(--color-primary);
  padding: 0 1rem;
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 0.3s ease-in-out,
    padding 0.3s ease-in-out;
}

.answer p {
  font-size: 1em;
  font-weight: bold;
}

.answer.open {
  max-height: 500px;
  padding: 1rem;
}

@media screen and (min-width: 768px) {
  .question h3 {
    font-size: 1.25em;
  }

  .answer p {
    font-size: 1.25em;
  }
}
