.cookiePolicy {
  min-height: 80vh;
  margin-top: 80px;
  padding: 20px;
  background: var(--color-white);
  color: var(--color-black);
  word-break: normal;
}

.cookieTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.cookieTable th,
.cookieTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.cookieTable th {
  background-color: #f2f2f2;
  text-align: left;
}
