.cookiePopup {
  height: 100vh;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
}

.cookieContent {
  position: fixed;
  bottom: 0;
  background-color: var(--color-white);
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  margin: 1rem;
}

.buttonContainer {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 10px;
}

.buttonContainer button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: var(--color-white);
  background-color: var(--color-primary);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
}

.buttonContainer button:hover {
  background-color: var(--color-secondary);
}

@media screen and (min-width: 768px) {
  .cookiePopup {
  }

  .cookieContent {
  }
}

@media screen and (max-width: 768px) {
  .buttonContainer {
    flex-direction: column;
    gap: 0px;
  }

  .buttonContainer button {
    margin: 5px 0;
  }
}
