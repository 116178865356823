.contact {
  padding: 2rem;
  background-color: var(--color-secondary);
}

.contact h2 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin-bottom: 2rem;
}

.contactForm label {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.contactForm label:last-child {
  font-size: 1rem;
}

.contactForm input,
.contactForm textarea {
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
}

.contactForm textarea {
  min-height: 150px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.checkboxContainer input {
  margin-right: 0.5rem;
}

.contactForm button {
  margin-top: 2rem;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--color-secondary);
  background-color: var(--color-primary);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-black);
  border-radius: 5px;
  box-shadow: 3px 3px 0px -1px var(--color-black);
}

.contactForm button:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
}

.contactText {
  max-width: 500px;
  text-align: center;
}
