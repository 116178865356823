.direction {
  padding: 2rem;
  background-color: var(--color-white);
}

.direction h2 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.directionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactInfo {
  flex: 1;
  margin-right: 2rem;
  max-width: 300px;
}

.contactInfo p {
  margin: 0.5rem 0;
  font-size: 1.25em;
}

.mapWrapper {
  flex: 2;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.mapWrapper iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.direction a {
  color: var(--color-black);
}

@media (min-width: 768px) {
  .directionContainer {
    flex-direction: row;
    align-items: flex-start;
  }

  .mapWrapper {
    height: 400px;
  }
}
